import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function CountryOfArbitrators(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>
            What Kind of Training is Required to Build a Country of Arbitrators?
          </HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              Arbitration in India has evolved over the preceding centuries to
              become one of the most well- regarded modes of dispute resolution,
              especially in the commercial world. In an arbitration, parties
              appoint an arbitrator or a panel of arbitrators forming an
              arbitral tribunal to hear the dispute and deliver an award based
              on merits.
            </p>
            <br />
            <p>
              The arbitrator is expected to offer privacy, confidentiality,
              flexibility and settle the disputes in a time-bound manner. The
              arbitrator also considers evidence without being bound by the
              strict requirements of codified procedural laws to pass the final
              decision.
            </p>
            <br />
            <p>
              The parties could decide the number of arbitrators, the seat and
              language of arbitration as well as the procedural rules that will
              apply to the dispute.
            </p>
            <br />
            <p>
              The appointment of arbitrators is an important aspect of the
              resolution process and thus, ensuring the proper accreditation and
              training of the arbitrator concerned is essential.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Qualifications as prescribed under the Arbitration and
              Conciliation Act, 1996
            </SubHeadWrap>
            <p>
              The arbitrator could belong to a formal body or association of
              arbitrators. They could have subject matter expertise or be
              experts in the substantive law of the contract. The Arbitration
              and Conciliation Act, 1996 was amended in 2019 which stipulated
              the setting up of an Arbitration Council of India whose functions
              included the grading and accreditation of arbitrators and arbitral
              institutions.<sup>1</sup>
            </p>
            <br />
            <p>
              Under the 2019 Amendment, the duties and functions of the
              Arbitration Council of India enumerated in Section 43D (2)
              included holding training, workshops and courses in the area of
              arbitration in collaboration with law firms, universities and
              arbitral institutes, to frame policies governing the grading of
              arbitral institutions, to recognise professional institutes
              providing accreditation of arbitrators as also to make
              recommendations regarding personnel, training and infrastructure
              of arbitral institutions.
            </p>
            <br />
            <p>
              Section 43J in the 2019 Amendment provided for the details of the
              qualifications, experience and norms for the accreditation of
              arbitrators as specified in the Eighth Schedule. This Eighth
              Schedule, which was added to the Act, provided that the arbitrator
              was required to have a minimum of ten years of experience in
              related fields or corresponding senior level managerial or
              administrative or academic experience. Apart from professional
              experience, the arbitrator was required to have a reputation for
              fairness, integrity, objectivity, impartiality and neutrality, to
              avoid conflict of interest and not have criminal antecedents.
            </p>
            <br />
            <p>
              The training of the arbitrator was expected to include familiarity
              with the Constitution of India, principles of natural justice,
              equity, common and customary laws, commercial laws, labour laws,
              law of torts, and the making and enforcing of arbitral awards. The
              arbitrator was expected to possess robust understanding of the
              domestic and international legal system and international best
              practices on arbitration as well as an understanding of the key
              elements of contractual obligations in civil and commercial
              disputes.
            </p>
            <br />
            <p>
              She was also expected to apply legal principles to a situation
              under dispute armed with keen insight of the judicial decisions on
              a given matter relating to arbitration. It also provided that the
              arbitrator should be capable of suggesting, recommending or
              writing a reasoned and enforceable arbitral award in any dispute
              which comes before her for adjudication.
            </p>
            <br />
            <p>
              However, in response to the critical feedback received on the
              limiting nature of the provisions, Section 3 of the 2021 Amendment
              to the Arbitration &amp; Conciliation Act, 1996 substituted
              Section 43J by stipulating that the qualifications, experience and
              norms for accreditation of arbitrators shall be as specified as
              per the regulations.
            </p>
            <br />
            <p>
              These regulations have been defined under Section 2 (1)(j) as the
              regulations made by the Arbitration Council under the Act. Section
              4 of the 2021 Amendment Act deleted the Eighth Schedule thus
              ensuring that a wide range of experts and professionals could
              become arbitrators without facing disqualification under the Act.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Training and experience
            </SubHeadWrap>
            <p>
              Arbitrators are expected to be experts in the field that they
              intend to practise in as well as possess the skills necessary for
              the successful conduct of arbitrations.  The arbitrators&#39;
              credentials would be considered greatly improved if they possess
              legal knowledge and subject matter expertise in the concerned
              technical areas.<sup>2</sup>  The ICC, alongside other
              international arbitration centres, provides courses and training
              in the Rules and Procedures concerning the conduct of arbitration.
            </p>
            <br />
            <p>
              There are several training institutes that provide training to
              arbitrators as also in acquiring the necessary soft skills. The{" "}
              <span style={{ fontStyle: "italic" }}>Salem Bar Association</span>{" "}
              case provides the initial framework of guidelines for the training
              of neutrals under Rule 7.
            </p>
            <br />
            <p>
              The High Court is to conduct training courses by requesting bodies
              offering legal education or retired Faculty Members or other
              persons well versed in the techniques of alternative methods of
              resolution of dispute, to hold training courses for lawyers and
              judicial officers.
            </p>
            <br />
            <p>
              The High Court and the District Courts are also expected to
              periodically conduct seminars and workshops on the subject of
              alternative dispute resolution processes throughout the State with
              a view to bring awareness of such procedures and to impart
              training to lawyers and judicial officers.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              In conclusion
            </SubHeadWrap>
            <p>
              It is clear that the legislative intention behind the Act and its
              Amendments is to ensure a well- regulated, skilful and
              professional body of arbitrators who comply with the requirements
              of independence and impartiality as stipulated under the UNCITRAL
              Model Law alongside other professional qualifications and
              experience. While there are numerous institutional options
              available to gain the necessary training, the evolution of the law
              on the eligibility and training of an arbitrator provides
              much-needed direction.
            </p>
            <Block />
            <p>
              <sup>1</sup> https://egazette.nic.in/WriteReadData/2019/210414.pdf
            </p>
            <p>
              <sup>2</sup>
              <a
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() =>
                  window.open(
                    "https://www.mondaq.com/nigeria/arbitration--dispute-resolution/1177100/building-a-career-in-international-arbitration-accreditation-training-and-specialization#_ftn11",
                  )
                }
              >
                https://www.mondaq.com/nigeria/arbitration--dispute-resolution/1177100/building-a-career-in-international-arbitration-accreditation-training-and-specialization#_ftn11
              </a>
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default CountryOfArbitrators;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
