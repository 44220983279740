import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";
import { Modal } from "@material-ui/core";
import TimeLine from "./TimeLine";

const HearingTimeline = ({ timelineModal, setTimelineModal }) => {
  const updatedArray = timelineModal?.data?.map((item) => {
    if (typeof item !== "object") {
      return { date: item, time: item }; // Create an object with the key 'value'
    }
    return item; // If it's already an object, return it as is
  });

  return (
    <Modal
      open={timelineModal?.state}
      onClose={() => setTimelineModal({ state: false })}
      disableRestoreFocus
    >
      <ModalContainer>
        <Container>
          <HeadingContainer>
            <Heading>{"Hearings Timeline"}</Heading>
          </HeadingContainer>
          <TimeLineCard className="timelines-container">
            <TimeLineCardHeight>
              {updatedArray?.length &&
                updatedArray
                  ?.sort((a, b) => {
                    const [dayA, monthA, yearTimeA] = a.date.split("/");
                    const [dayB, monthB, yearTimeB] = b.date.split("/");

                    const dateA = new Date(
                      `${yearTimeA.split(" ")[0]}-${monthA}-${dayA} ${
                        yearTimeA.split(" ")[1]
                      }`,
                    );
                    const dateB = new Date(
                      `${yearTimeB.split(" ")[0]}-${monthB}-${dayB} ${
                        yearTimeB.split(" ")[1]
                      }`,
                    );

                    return dateA - dateB;
                  })
                  .map((data, idx) => <TimeLine data={data} key={idx} />)}
            </TimeLineCardHeight>
          </TimeLineCard>

          <FormControls>
            <PrimaryCTAButton
              style={{ width: "42%", padding: "8px 8px" }}
              onClick={() => setTimelineModal({ state: false })}
            >
              Close
            </PrimaryCTAButton>
          </FormControls>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default HearingTimeline;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 18px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 35px;
  padding-bottom: 30px;
`;

const TimeLineCard = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  @media${theme.breakpoints.sm_up} {
    width: 90%;

    justify-content: flex-end;
  }
  @media only screen and (max-width: 1023px) {
    .timelines-item-content {
      max-width: 80%;
    }
  }
`;

const TimeLineCardHeight = styled.div`
  max-height: 280px;
  overflow: auto;
`;
