import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function RoleOfMediation(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>ROLE OF MEDIATION IN SME DISPUTES</HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              Small and Medium Sized Enterprises (SME) are categorized as
              businesses whose investment, revenue and employee base are
              restricted to predetermined limits. Considering that SMEs do not
              have the resources for protracted versions of conflict resolution
              including litigation, mediation appears to be far better suited to
              resolving SME disputes.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Why Mediation?
            </SubHeadWrap>
            <p>
              Mediation as a mode is inexpensive, flexible and informal as it
              helps parties reach conclusive settlements earlier than other
              conventional forms of dispute resolution. The mediator, when
              skilled and experienced, facilitates fruitful negotiation between
              the parties and is aware of techniques to break deadlocks that
              emerge from difficult negotiations. The mediator does not
              determine the rights and liabilities involved; it is in fact, the
              parties’ interests and needs that lead the process.
            </p>
            <br />
            <p>
              Litigation, as is well known, is time- and cost-intensive. Cases
              require expenses to be met, including lawyers’ fees, court fees,
              and other miscellaneous charges like conveyance, refreshment,
              prints, apart from mandatory attendance. There is high pendency of
              matters as well due to the volume of incoming litigation and slow
              disposal rates. Hearings are set up on inconvenient dates and
              locations for the parties without taking their availability and
              needs into consideration.
            </p>
            <br />
            <p>
              Litigation generally, due to its adversarial nature, leads to
              fractured relationships. Modes of alternative dispute resolution,
              among other benefits, ensure that parties resolve their
              differences amicably in a conducive setting. This is an important
              requirement for SME business equations since this allows for
              long-term engagement with low risks.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Why Mediation may work better than Arbitration
            </SubHeadWrap>
            <p>
              Here are a few reasons why mediation is a better option for SMEs:
            </p>
            <br />
            <p>
              In mediation, parties have more control over the resolution
              process than they do in arbitration and litigation. The mediator
              is a facilitator i.e. he does not determine rights in favour of
              one party against the other.
            </p>
            <br />
            <p>
              Mediation is not an adversarial process unlike litigation and to a
              limited extent, arbitration (since the award is passed in favour
              of only one party). The process of mediation allows for closed and
              open discussions between the parties concerned so that a
              comprehensive understanding of the dispute may be brought forward,
              in the presence of the mediator.
            </p>
            <br />
            <p>
              Mediators offer subject expertise which could be very beneficial
              for SMEs. SMEs may be unable to hire committed legal counsel to
              advise them on commercial matters and dispute resolution unlike
              bigger, more powerful organisations. Therefore, mediation as a
              means of resolving disputes can balance the power dynamics between
              mismatched parties since MSME businesses cannot afford legal
              dispute resolution teams unlike larger organisations.
            </p>
            <br />
            <p>
              The parties are encouraged to understand and respond to each
              other’s needs, interests, concerns and goals in a way that drives
              the negotiation forward. This gives the mediator the space to
              develop solutions that are unique and customised to fit the
              parties’ needs which may not be possible in arbitration and
              litigation.
            </p>
            <br />
            <p>
              The parties are allowed to exit the mediation whenever they choose
              to. The purpose of mediation is to lessen the conflict between the
              parties and eventually reach settlement with the help of the
              mediator.
            </p>
            <br />
            <p>
              Since mediation is confidential, it gives the parties space to
              speak openly and without fear. If it is found that the first and
              subsequent offers are not satisfactory, and the mediator is unable
              to come up with a settlement that is acceptable, the parties
              reserve the right to quit the process at any point.
            </p>
            <br />
            <p>
              Conflicts and disputes can take a severe financial and emotional
              toll on small enterprises which anyway compete in challenging
              business landscapes. Mediation allows these businesses the space
              to focus on growth and development while providing a mode of
              dispute resolution that could manage complex interpersonal
              business dynamics and conflict.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              In conclusion
            </SubHeadWrap>
            <p>
              Due to the burden of time and other costs on SMEs in risk-heavy
              processes like litigation and arbitration, mediation offers a
              speedy, effective and flexible alternative to reach constructive
              outcomes and could thus be most appropriate for the SME ecosystem.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default RoleOfMediation;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
