import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import StyledAppBar from "./StyledAppBar";
import Sidebar from "./Sidebar";
import useDialog from "../../../hooks/useDialog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { P, LargeSidebar } from "../../pages/LandingPage/Styles";
import labels from "../../../helpers/labels.json";
import { PrimaryOutlinedCTAButton } from "../../common/Buttons";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import useCartCount from "../../../hooks/useCartCount";
import { useEffect } from "react";
import useUser from "../../../hooks/useUser";
import _ from "lodash";
import CaseService from "../../../services/CaseService";
import { useLocation } from "@reach/router";
import { sanitize } from "dompurify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    marginTop: 74,
    // overflowX: "auto",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:600px)"]: {
      width: "100%",
    },
  },
}));

export function Header({
  children,
  showSidebar = false,
  selectedItem,
  NavPages = false,
  hideLinks = false,
  LandingPage = false,
  selectedSubItem = "",
  hideIcon = false,
}) {
  const classes = useStyles();
  const { state, setState } = useDialog();
  const { translaterOpen } = useContext(TranslaterContext);
  const { userObject, password } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const {
    triggerCount,
    setTriggerCount,
    setCartCount,
    cartCount,
    stats,
    setStats,
  } = useCartCount();

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(userObject) && triggerCount && showSidebar) {
      if (userObject?.emailVerified) {
        CaseService.caseStats().then(
          (res) => {
            setCartCount(res?.inCartTotalCount);
            setTriggerCount(false);
            setStats(res);
          },
          (err) => {
            setTriggerCount(false);
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject, triggerCount, showSidebar]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar
        NavPages={NavPages}
        {...{
          hideLinks,
          cartCount,
          LandingPage,
          setShowDrawer,
          showDrawer,
          selectedItem,
          stats,
          hideIcon,
          password,
        }}
        selectedSubItem={
          searchParams.get("caseType")
            ? searchParams.get("caseType")
            : selectedSubItem
        }
      />
      <LargeSidebar>
        {showSidebar && (
          <Sidebar
            {...{
              selectedItem,
              stats,
              showDrawer,
              setShowDrawer,
              LandingPage,
              userObject,
              password,
            }}
            selectedSubItem={
              searchParams.get("caseType")
                ? searchParams.get("caseType")
                : selectedSubItem
            }
          />
        )}
      </LargeSidebar>
      <main
        className={classes.content}
        style={translaterOpen ? { marginTop: `40px !important` } : {}}
      >
        {children}
      </main>

      {/* DialogComponent for term and condition and other links */}
      <Dialog
        open={!!state}
        maxWidth={"md"}
        onClose={() => setState("")}
        disableBackdropClick
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {state === "T&C" && "Terms and Conditions"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <div style={{ paddingBottom: 15 }}>
              {state === "T&C" && (
                <P
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(labels["landing.T&C"]),
                  }}
                />
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryOutlinedCTAButton
            style={{
              marginRight: 30,
              height: 39,
              width: 124,
              padding: "11px 38px",
            }}
            onClick={() => setState("")}
          >
            Agree
          </PrimaryOutlinedCTAButton>
        </DialogActions>
      </Dialog>
      {/* DialogComponent */}
    </div>
  );
}
