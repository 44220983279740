/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { states } from "./constants";
import { isValidPhoneNumber } from "libphonenumber-js";

function validatePinCodeByState(state, pin) {
  if (state && pin) {
    const found = states.find((st) => st.value === state);
    const pinStart = pin?.toString().substring(0, 2);
    if (found && found.pinStart.includes(parseInt(pinStart, 10))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const validatePassword = (password) => {
  if (
    password.length < 8 ||
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[0-9]/.test(password) ||
    !/[@#$%^&+*!=]/.test(password)
  ) {
    return false;
  }
  return true;
};
const PAN_CARD_REGEX = (input) => {
  // Ensure the input is exactly 10 characters long
  if (input.length !== 10) {
    return false;
  }

  // Check the first 5 characters are letters (case-insensitive)
  if (!/^[a-zA-Z]{5}/.test(input)) {
    return false;
  }

  // Check the next 4 characters are digits
  if (!/^[0-9]{4}/.test(input.substring(5, 9))) {
    return false;
  }

  // Check the last character is a letter (case-insensitive)
  if (!/^[a-zA-Z]$/.test(input[9])) {
    return false;
  }
  return true;
};

const GST_VALIDATION = (input) => {
  if (input.length !== 13) {
    return false;
  }

  // Check for the pattern: \d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}Z[A-Z\d]{1}
  return /^\d{2}[A-Z]{5}\d{4}[A-Z][A-Z\d]Z[A-Z\d]$/.test(input);
};

// const PAN_CARD_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const VOTER_REGEX = /^([a-zA-Z]){3}([0-9]){7}?$/;
const AADHAAR_REGEX_TWELVE = /^\d{4}\d{4}\d{4}$/;
const AADHAAR_REGEX_SIXTEEN = /^\d{4}\d{4}\d{4}\d{4}$/;
const CIN_REGEX = /^([LU])(\d{5})([A-Za-z]{2})(\d{4})([A-Za-z]{3})(\d{6})$/;
const MOBILE_REGEX =
  /^(\+\d{1,2})?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(x\d+)?$/;
// const PASSWORD_RULE = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
// const GST_VALIDATION = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
// const PASSWORD_REGEX =
//   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

export const signupValidationSchema = Yup.object({
  fullname: Yup.string().trim().required("Full Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
  mobile: Yup.string("Please enter valid phone number")
    // .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
    .test("mobile", "Please enter valid phone number", async function (value) {
      if (!isValidPhoneNumber(String(value), "IN")) {
        return false;
      } else {
        return true;
      }
    }),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be minimum 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      },
    ),
  // password: Yup.string()
  //   .matches(
  //     PASSWORD_REGEX,
  //     "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character"
  //   )
  //   .min(6, "Password must be minimum 8 characters")
  //   .required("Password is required"),
});

export const signupWithGoogle = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const profileCompletionValidationSchema = Yup.object({
  dob: Yup.date().nullable().required("Date of Birth is required"),
  address_line: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  state: Yup.string().trim().required("State is required"),
  pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.state, value);
      } else {
        return false;
      }
    }),
  landmark: Yup.string()
    .trim()
    // .max(30, "Landmark is too long")
    .required("Landmark is required"),
});

export const LoginEmailValidationSchema = Yup.object({
  email: Yup.string().email("Please enter valid email").required(),
  password: Yup.string()
    .min(6, "Please enter 8 characters")
    .required("Password is required"),
});

export const LoginMobileValidationSchema = Yup.object({
  mobile: Yup.string("Please enter valid phone number")
    // .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required")
    .test("mobile", "Please enter valid phone number", async function (value) {
      if (!isValidPhoneNumber(String(value), "IN")) {
        return false;
      } else {
        return true;
      }
    }),
});

export const createPartyValidationSchema = Yup.object({
  document_house_no: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  document_locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  document_city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  document_state: Yup.string().trim().required("State is required"),
  document_pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.document_state, value);
      } else {
        return false;
      }
    }),
  document_landmark: Yup.string()
    .trim()
    .nullable()
    // .max(30, "Landmark is too long")
    .required("Landmark is required"),
  document_uri: Yup.string().trim().nullable().required("Document is required"),
  doc_number: Yup.string()
    .trim()
    .test("document-number", "", function (value) {
      if (this?.parent?.document_kind === "pan") {
        // const matched = value?.match(PAN_CARD_REGEX);
        const matched = value ? PAN_CARD_REGEX(value) : false;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "PAN Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid PAN Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "aadhaar") {
        const matched =
          value?.match(AADHAAR_REGEX_TWELVE) ||
          value?.match(AADHAAR_REGEX_SIXTEEN);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Aadhaar Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid Aadhaar Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "cin") {
        const matched = value?.match(CIN_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "CIN Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid CIN Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "voter") {
        const matched = value?.match(VOTER_REGEX);
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Voter ID Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Enter a valid Voter ID Number",
            path: "doc_number",
          });
        }
      } else if (this?.parent?.document_kind === "passport") {
        if (value) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Passport Number is required",
              path: "doc_number",
            });
          return this.createError({
            message: "Passport number is required",
            path: "doc_number",
          });
        }
      }
    })
    .nullable(),
  name: Yup.string().trim().required("Party Name is required"),
  gstNumber: Yup.string()
    .trim()
    .nullable()
    .test("gstNumber", "Please enter valid GST number", async function (value) {
      if (value?.toString()?.length) {
        return GST_VALIDATION(value);
      } else {
        return true;
      }
    }),
  // .matches(GST_VALIDATION, "Please enter valid GST number"),
  gstCertUrl: Yup.string()
    .trim()
    .test("gstCertUrl", "", function (value) {
      if (this?.parent?.gstNumber) {
        const matched = value?.gstNumber;
        if (matched) {
          return true;
        } else {
          if (!value)
            return this.createError({
              message: "Document is required",
              path: "gstCertUrl",
            });
        }
      }
    }),
  document_billing_house_no: Yup.string()
    .trim()
    .required("Billing Address is required"),
  document_billing_locality: Yup.string()
    .trim()
    .required("Billing Locality is required"),
  document_billing_city: Yup.string()
    .trim()
    .required("Billing City is required"),
  document_billing_state: Yup.string()
    .trim()
    .required("Billing State is required"),
  document_billing_pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.document_state, value);
      } else {
        return false;
      }
    }),
  // document_billing_pin: Yup.string().trim().required("Billing Pincode is required"),
  document_billing_landmark: Yup.string()
    .trim()
    .required("Billing Landmark is required"),
  // gstCertUrl: Yup.string().trim().nullable(),
});

export const AddAgentValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Please enter valid email")
    .required("Agent email is required"),
  pan: Yup.string().test(
    "pan",
    "Enter a valid PAN number",
    async function (value) {
      if (value?.toString()?.length) {
        return PAN_CARD_REGEX(value);
      } else {
        return true;
      }
    },
  ),
  // pan: Yup.string().matches(PAN_CARD_REGEX, "Enter a valid PAN number"),
  // .required("Agent PAN Number is required"),
  // uri: Yup.string().trim().required("Authorisation Letter is required"),
});

export const CreateCaseStep1Schema = Yup.object({
  claimantPartyId: Yup.string().required("Party is required"),
  resolutionKind: Yup.string(),
  description: Yup.string().required("Case Description is required"),
  totalClaimValue: Yup.string()
    .test("not-zero", "Total Claim value is required", function (value) {
      if (this?.parent?.resolutionKind === "negotiation") {
        return true;
      } else if (value) {
        return true;
      }
      return false;
    })
    .typeError("Claim value cannot be empty"),
  agreement_urls: Yup.array().of(
    Yup.object().shape({
      uploadType: Yup.string()
        .trim()
        .test(
          "documenttype_required",
          "Document Type is required",
          function (value) {
            if (
              this?.options?.values?.resolutionKind === "arbitration" &&
              !value &&
              this.parent.url
            ) {
              return false;
            }
            return true;
          },
        ),
      url: Yup.string()
        .trim()
        .test("document_required", "Document is required", function (value) {
          if (
            this?.options?.values?.resolutionKind === "arbitration" &&
            !value &&
            this.parent.uploadType
          ) {
            return false;
          }
          return true;
        }),
    }),
  ),
  claim_items_data: Yup.array().of(
    Yup.object({
      title: Yup.string()
        .trim()
        .test("title", "Title is required", function (value) {
          if (this?.options?.values?.resolutionKind === "mediation" && !value) {
            return false;
          }
          return true;
        }),
      claimValue: Yup.string()
        .trim()
        .test("not-zero", "Claim value cannot be empty", function (value) {
          if (this?.options?.values?.resolutionKind === "mediation" && !value) {
            return value;
          }
          return true;
        })
        .typeError("Claim value cannot be empty"),
    }),
  ),
  subscriptionKind: Yup.string(),
  requestedMediators: Yup.array().of(
    Yup.object().shape({
      mediatorName: Yup.string()
        .trim()
        .test("name", `Name is required`, function (value) {
          return (
            value ||
            this?.options?.values?.resolutionKind !== "arbitration" ||
            this?.options?.values?.subscriptionKind !== "adhoc"
          );
        }),
      mediatorEmail: Yup.string()
        .email("Please enter a valid email")
        .test("email", "Email is required", function (value) {
          if (
            this?.options?.parent?.mediatorName &&
            !this?.options?.parent?.mediatorEmail
          ) {
            return false;
          } else if (!value) {
            return (
              false ||
              this?.options?.values?.resolutionKind !== "arbitration" ||
              this?.options?.values?.subscriptionKind !== "adhoc"
            );
          }
          return true;
          // return value || this?.options?.values?.subscriptionKind !== "adhoc";
        }),
      mediatorMobile: Yup.string().test(
        "phone",
        "Enter a valid mobile number",
        function (value) {
          if (value) {
            return value?.length === 10 || value?.length === 13;
          }
          return true;
        },
      ),
      mediatorDocuments: Yup.string()
        .trim()
        .test("documents", "Document is required", function (value) {
          if (
            this.options.parent.documents === "error" &&
            !this?.options?.parent?.mediatorDocuments
          ) {
            return false;
          }
          return true;
          // return value || this.options.parent.mediatorDocuments !== "error";
        }),
    }),
  ),
});

export const CaseRequestSchema = Yup.object({
  partyId: Yup.string().trim().required("Party is Required"),
});

export const CreateCaseStep2Schema = Yup.object({
  respondentParties: Yup.array().of(
    Yup.object().shape({
      respondentKind: Yup.string()
        .trim()
        .required("Respondent kind is required"),
      respondentName: Yup.string()
        .trim()
        .required("Respondent name is required"),
      respondentEmail: Yup.string()
        .email("Please enter a valid email")
        .required("Respondent email is required"),
      respondentMobile: Yup.string()
        .max(10, "Enter a valid mobile number")
        .min(10, "Enter a valid mobile number"),
    }),
  ),
});

export const CreateCaseStep3Schema = Yup.object({
  claim_items: Yup.array(
    Yup.object({
      title: Yup.string().trim().nullable().required("Title is required"),
      claimValue: Yup.string()
        .test(
          "not-zero",
          "Claim value must be greater than zero",
          function (value) {
            return value;
          },
        )
        .typeError("Claim value cannot be empty"),
      unit: Yup.string().trim().nullable().required("Unit is required"),
      description_document_url: Yup.string().trim().nullable(),
      description: Yup.string().trim().nullable(),
    }),
  ),
});

export const EditProfileSchema = Yup.object({
  houseNumber: Yup.string()
    .trim()
    // .max(20, "Address is too long")
    .required("Address is required"),
  locality: Yup.string()
    .trim()
    // .max(20, "Locality is too long")
    .required("Locality is required"),
  city: Yup.string()
    .trim()
    // .max(20, "City is too long")
    .required("City is required"),
  landmark: Yup.string()
    .trim()
    // .max(30, "Landmark is too long")
    .required("Landmark is required"),
  state: Yup.string().trim().required("State is required"),
  pin: Yup.number()
    .required("Pincode is required")
    .test("pincode", "Invalid Pincode", async function (value) {
      if (value?.toString()?.length === 6) {
        return validatePinCodeByState(this.parent.state, value);
      } else {
        return false;
      }
    }),
  panNumber: Yup.string(),
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "password",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      },
    ),
  // password: Yup.string()
  //   .matches(
  //     PASSWORD_RULE,
  //     "Password should have a minimum of 1 digit and 1 letter"
  //   )
  //   .min(6, "Please enter 6 characters")
  //   .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.password === value;
    }),
});

export const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, "Please enter 6 characters")
    .required("Current Password is required"),

  newPassword: Yup.string()
    .required("New Password is required")
    .min(6, "Please enter 8 characters")
    .test(
      "newPassword",
      "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character",
      async function (value) {
        if (value?.toString()?.length) {
          return validatePassword(value);
        } else {
          return false;
        }
      },
    ),
  // newPassword: Yup.string()
  //   .matches(
  //     PASSWORD_REGEX,
  //     "Password should have a minimum of 8 characters and 1 uppercase and 1 numeral and 1 special character"
  //   )
  //   .min(6, "Please enter 8 characters")
  //   .required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .min(6, "Password must be minimum 8 characters")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export const BulkUploadSchema = Yup.object({
  partyId: Yup.string().trim().required("Party is required"),
  url: Yup.string().required("Case file is required"),
});

export const DiscussFormValidationSchema = Yup.object({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .trim()
    .email("Please enter valid email")
    .required("Email is required"),
  mobile: Yup.string("Please enter valid phone number")
    .matches(MOBILE_REGEX, "Please enter valid phone number")
    .required("Phone number is required"),
});

export const CreateGenericMeeting = Yup.object({
  name: Yup.string("Please enter valid phone number")
    .trim()
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email id is Required"),
});

export const OfflinePaymentSchema = Yup.object({
  paymentType: Yup.string().trim().required("Payment type is required"),
  details: Yup.string().optional(),
});

export const CreateMediatorSchema = Yup.object({
  requestedMediators: Yup.array().of(
    Yup.object().shape({
      mediatorName: Yup.string()
        .trim()
        .test("name", `Name is required`, function (value) {
          if (this.options.values.arbitratorType === "old") {
            return true;
          } else {
            if (value) {
              return true;
            } else {
              return false;
            }
          }
        }),
      mediatorEmail: Yup.string()
        .email("Please enter a valid email")
        .test("email", "Email is required", function (value) {
          if (this.options.values.arbitratorType === "old") {
            return true;
          } else {
            if (value) {
              return true;
            } else {
              return false;
            }
          }
        }),
      mediatorMobile: Yup.string().test(
        "phone",
        "Enter a valid mobile number",
        function (value) {
          if (value) {
            return value?.length === 10 || value.length === 13;
          }
          return true;
        },
      ),
      mediatorDocuments: Yup.string()
        .trim()
        .test("documents", "Document is required", function (value) {
          if (
            this.options.parent.documents === "error" &&
            !this?.options?.parent?.mediatorDocuments
          ) {
            return false;
          }
          return true;
          // return value || this.options.parent.mediatorDocuments !== "error";
        }),
    }),
  ),
});

export const ModifyCaseStep1Schema = Yup.object({
  resolutionKind: Yup.string(),
  subscriptionType: Yup.string(),
  partyId: Yup.string().trim().required("Party is Required"),
  counterClaimDescription: Yup.string()
    .trim()
    .test(
      "counterClaimDescription",
      `Counter Claim Description is required`,
      function (value) {
        if (!value && this?.options?.values?.counterClaimType) {
          return false;
        }
        return true;
        // return value || !this?.options?.values?.subscriptionType;
      },
    ),
  counterClaim: Yup.string()
    .test("not-zero", "Total Claim value is required", function (value) {
      if (!value && this?.options?.values?.counterClaimType) {
        return false;
      }
      return true;
      // return value || !this?.options?.values?.subscriptionType;
    })
    .typeError("Claim value cannot be empty"),
  // agreement_urls: Yup.array().of(
  //   Yup.object().shape({
  //     uploadType: Yup.string()
  //       .trim()
  //       .test(
  //         "documenttype_required",
  //         "Document Type is required",
  //         function (value) {
  //           if (!value && this?.options?.values?.counterClaimType) {
  //             return false;
  //           }
  //           if (
  //             this?.options?.values?.resolutionKind === "arbitration" &&
  //             !value &&
  //             this.parent.url
  //           ) {
  //             return false;
  //           }
  //           return true;
  //         }
  //       ),
  //     url: Yup.string()
  //       .trim()
  //       .test("document_required", "Document is required", function (value) {
  //         if (!value && this?.options?.values?.counterClaimType) {
  //           return false;
  //         }
  //         if (
  //           this?.options?.values?.resolutionKind === "arbitration" &&
  //           !value &&
  //           this.parent.uploadType
  //         ) {
  //           return false;
  //         }
  //         return true;
  //       }),
  //   })
  // ),
  subscriptionKind: Yup.string(),
  requestedMediators: Yup.array().of(
    Yup.object().shape({
      mediatorName: Yup.string()
        .trim()
        .test("name", `Name is required`, function (value) {
          if (this.options.values.subscriptionType === "old") {
            return true;
          } else {
            if (value) {
              return true;
            } else {
              return false;
            }
          }
        }),
      mediatorEmail: Yup.string()
        .email("Please enter a valid email")
        .test("email", "Email is required", function (value) {
          if (this.options.values.subscriptionType === "old") {
            return true;
          } else {
            if (value) {
              return true;
            } else {
              return false;
            }
          }
        }),
      mediatorMobile: Yup.string().test(
        "phone",
        "Enter a valid mobile number",
        function (value) {
          if (value) {
            return value?.length === 10 || value.length === 13;
          }
          return true;
        },
      ),
      mediatorDocuments: Yup.string()
        .trim()
        .test("documents", "Document is required", function (value) {
          if (
            this.options.parent.documents === "error" &&
            !this?.options?.parent?.mediatorDocuments
          ) {
            return false;
          }
          return true;
          // return value || this.options.parent.mediatorDocuments !== "error";
        }),
    }),
  ),
});

export const BulkNoticeGenerationSchema = Yup.object({
  partyId: Yup.string().trim().required("Party id is required"),
  templateCode: Yup.string().trim().required("Template code is required"),
  lotNo: Yup.string().trim().required("Lot number is required"),
  excelFile: Yup.string().trim().required("Excel template is required"),
  noticeCategory: Yup.string().trim().required("Notice category is required"),
});

export const GenerateBunldeSchema = Yup.object({
  pageNumber: Yup.string().test(
    "pageNumber",
    "Page no should be less than five hundred",
    function (value) {
      if (value <= 500) {
        return true;
      }
      return false;
    },
  ),
});
