import { navigate, useLocation } from "@reach/router";
import { Formik, yupToFormErrors } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { caseDocumentType } from "../../../helpers/constants";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import { ModifyCaseStep1Schema } from "../../../helpers/validationSchema";
import useLoader from "../../../hooks/useLoader";
import AWSService from "../../../services/AWSService";
import PartyService from "../../../services/PartyService";
import ActionBar from "../../common/ActionBar";
import FormField from "../../common/FormField/FormField";
import AdditionalMediator from "../CreateCase/components/AdditionalMediator";
import MultipleUpload from "../CreateCase/components/MultipleUpload";
import PartySelect from "../CreateCase/components/PartySelect";
import TotalClaimValue from "../CreateCase/components/TotalClaimValue";
import {
  setInitialMediator,
  setInitialAgreementUrl,
  dummyFileUpload,
  setPostPayloadMediators,
} from "../CreateCase/function";
import {
  Container,
  CreateCaseContainer,
  StyledForm,
  StyledFormElementsWrapper,
  StyledLabel,
  StyledFormElementsWrapper1,
  AccordionContainer,
  HyperLink,
  AccordionHeading,
} from "./Styles";
import CaseActionCTA from "../CreateCase/components/CaseActionCTA";
import {
  useMediaQuery,
  useTheme,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { CustomRadioGroup, CustomRadio } from "../../common/FormInputs";
import CustomTable from "../../common/CustomTable/CustomTable";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import useAlert from "../../../hooks/useAlert";
import useCartCount from "../../../hooks/useCartCount";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";

const inputs = [
  {
    type: "textarea",
    name: "counterClaimDescription",
    label: "Counter Claim Description",
    required: true,
  },
];

const Index = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const breadcrumbs = ["My Cases", "Respondent accept case"];
  const location = useLocation();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  const { hideAlert, showAlert } = useAlert();
  const { setTriggerCount } = useCartCount();

  const caseObj = {};

  useEffect(() => {
    async function getParties(params) {
      try {
        setLoader({ state: true });
        const response = await PartyService.getParties(
          "?perPage=50&status=active"
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id }) => ({ label: name, value: id }))
          );
        } else {
          enqueueSnackbar(
            "You need to create a Party inorder to create a case",
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        window.scrollTo(0, 0);
        setLoader({ state: false });
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "my_cases":
        navigate("/dashboard/cases?caseType=arbitration");
        break;
      default:
        break;
    }
  };

  const handleDeleteAgreement = (index, agreement_urls, setFieldValue) => {
    if (agreement_urls?.length) {
      const filtered = agreement_urls.filter((v, idx) => idx !== index);
      setFieldValue("agreement_urls", filtered);
    }
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  const acceptCase = async (
    {
      partyId,
      counterClaim,
      counterClaimDescription,
      requestedMediators,
      counterClaimCurrencyUnit,
      counterClaimType,
      subscriptionType,
      agreement_urls,
    },
    registrationFee
  ) => {
    try {
      if (partyId && location?.state?.id) {
        setLoader({ state: true, message: "Accepting Case..." });
        const updatedMediators = setPostPayloadMediators(requestedMediators);
        let payload;
        if (counterClaimType && subscriptionType === "new") {
          payload = {
            counterClaim: Number(
              counterClaim?.toString()?.split(",")?.join("")
            ),
            counterClaimDescription: counterClaimDescription,
            // counterClaimCurrencyUnit: counterClaimCurrencyUnit,
            agreement_urls: agreement_urls,
            requestedMediators: [
              ...location?.state?.requestedMediators,
              ...updatedMediators?.requestedMediators,
            ],
          };
        } else if (counterClaimType && subscriptionType === "old") {
          payload = {
            counterClaim: Number(
              counterClaim?.toString()?.split(",")?.join("")
            ),
            counterClaimDescription: counterClaimDescription,
            // counterClaimCurrencyUnit: counterClaimCurrencyUnit,
            agreement_urls: agreement_urls,
          };
        } else if (!counterClaimType && subscriptionType === "new") {
          payload = {
            requestedMediators: [
              ...location?.state?.requestedMediators,
              ...updatedMediators?.requestedMediators,
            ],
          };
        }

        if (payload) {
          const response = await CaseService.acceptCaseData(
            location?.state?.id,
            partyId,
            payload
          );

          if (response) {
            enqueueSnackbar("Case accepted successfully", {
              variant: "success",
            });
            if (registrationFee && Number(registrationFee)) {
              setTriggerCount(true);
              navigate("/dashboard/cart");
            } else {
              navigate(
                "/dashboard/cases/" +
                  location?.state?.id +
                  `?caseType=${location?.state?.resolutionKind}`
              );
            }
          }
        } else {
          const response = await CaseService.acceptCase(
            location?.state?.id,
            partyId
          );

          if (response) {
            enqueueSnackbar("Case accepted successfully", {
              variant: "success",
            });
            if (registrationFee && Number(registrationFee)) {
              setTriggerCount(true);
              navigate("/dashboard/cart");
            } else {
              navigate(
                "/dashboard/cases/" +
                  location?.state?.id +
                  `?caseType=${location?.state?.resolutionKind}`
              );
            }
          }
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      hideAlert();
    }
  };

  const acceptPaymentInformationAlert = (registrationFee, res, values) => {
    showAlert({
      heading: "Accept Case Confirmation",
      desc: (
        <UnorderList>
          {Number(values?.counterClaim?.toString()?.split(",")?.join("")) > 0 &&
            values?.counterClaimType && (
              <li>
                Case filing Fee -{" "}
                <b
                  style={{
                    fontFamily: `${theme.fonts.primaryFontExtraBold}`,
                  }}
                >
                  {registrationFee?.unit}{" "}
                  {parseFloat(registrationFee?.total).toFixed(2) || 0.0}
                </b>
              </li>
            )}

          <li>
            Potential Arbitration Fee -{" "}
            <b
              style={{
                fontFamily: `${theme.fonts.primaryFontExtraBold}`,
              }}
            >
              {registrationFee?.unit}{" "}
              {parseFloat(registrationFee?.potentialCost).toFixed(2) || 0.0}
            </b>
          </li>

          {registrationFee?.total &&
            Number(values?.counterClaim?.toString()?.split(",")?.join("")) >
              0 &&
            values?.counterClaimType && (
              <>
                <div style={{ marginTop: "5px" }} />

                <TextAlign style={{ fontStyle: "italic", fontSize: "13px" }}>
                  * Case Filing Fee shall be paid now
                </TextAlign>
              </>
            )}
          <div style={{ marginTop: "5px" }} />
          <TextAlign style={{ fontStyle: "italic", fontSize: "13px" }}>
            * Arbitration Fee shall be paid over stages
          </TextAlign>
        </UnorderList>
      ),
      primaryBtnText: "Accept",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => acceptCase(values, registrationFee?.total),
      isOpen: true,
      onDialogClose: () => hideAlert(),
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  const adhocPaymentInformationAlert = (registrationFee, values) => {
    showAlert({
      heading: "Accept Case Confirmation",
      desc: (
        <div className="adhoc-pricing">
          <li>
            <b>
              You will be charged a non-refundable license fee of{" "}
              {registrationFee.unit}{" "}
              {parseFloat(registrationFee?.total).toFixed(2) || 0.0} for using
              JustAct. The license validity is {registrationFee?.roomValidity}{" "}
              months.
            </b>
          </li>
          <br />
          <li>
            <b>
              You will also need to pay the{" "}
              {location?.state?.resolutionKind === "mediation"
                ? "mediator"
                : "arbitrator"}{" "}
              as per the terms agreed between the parties and the{" "}
              {location?.state?.resolutionKind === "mediation"
                ? "mediator"
                : "arbitrator"}
              . JustAct will not be responsible for any non-payment in this
              regard.
            </b>
          </li>
        </div>
      ),
      primaryBtnText: "Accept",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => acceptCase(values, registrationFee?.total),
      isOpen: true,
      onDialogClose: () => hideAlert(),
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  async function onFormSubmit(values) {
    let counterClaim = Number(
      values.counterClaim?.toString()?.split(",")?.join("")
    );
    if (
      location?.state?.respondentStatus === "accepted" ||
      location?.state?.paymentCode
    ) {
      // already accepted by another respondent or case contains payment code
      acceptCase(values); // Accept directly when 2 respondent accept the case
    } else {
      try {
        setLoader({ state: true, message: "Fetching details..." });
        const registrationFee =
          (
            await CaseService.getCase(
              location?.state?.id,
              `?estimateFeeKind=registrationFee&counterClaim=${counterClaim}&partyId=${values?.partyId}`
            )
          )?.fee || {};
        if (location?.state?.subscriptionKind === "adhoc") {
          adhocPaymentInformationAlert(registrationFee, values);
        } else {
          const res = await CaseService.getCase(
            location?.state?.id,
            `?estimateFeeKind=successFee&estimateRespondentPartyId=${values?.partyId}`
          );
          if (res?.fee?.total) {
            acceptPaymentInformationAlert(registrationFee, res, values);
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Container>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <CreateCaseContainer className="CreateCase">
        <Formik
          initialValues={{
            partyId: menuItems.length
              ? caseObj?.claimantPartyId
                ? caseObj.claimantPartyId
                : menuItems[0].value
              : "" || "",
            requestedMediators: setInitialMediator(caseObj),
            counterClaimDescription: caseObj?.counterClaimDescription || "",
            // counterClaimCurrencyUnit:
            //   caseObj?.counterClaimCurrencyUnit || "INR",
            resolutionKind: "arbitration",
            agreement_urls:
              caseObj?.agreement_urls && caseObj.agreement_urls.length
                ? setInitialAgreementUrl(caseObj.agreement_urls)
                : [dummyFileUpload],
            subscriptionKind: "adhoc",
            subscriptionType: "old" || "",
            counterClaim: caseObj?.counterClaim || 0,
            counterClaimType: 0 || "",
          }}
          validate={(values) => {
            try {
              ModifyCaseStep1Schema.validateSync(values, {
                abortEarly: false,
                values,
              });
            } catch (e) {
              return yupToFormErrors(e);
            }
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => (
            <StyledForm>
              {/* header part */}
              {/* drop down select claimant party */}
              <PartySelect
                {...{
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  menuItems,
                }}
                label={"Select a Respondent Party"}
                name={"partyId"}
              />
              <StyledFormElementsWrapper1
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <StyledLabel>Counter Claim</StyledLabel>
                <CustomRadio
                  radioInputs={[
                    {
                      label: "File a counter claim",
                      value: "counterClaimType",
                    },
                  ]}
                  name={"counterClaimType"}
                  handleChange={(e) => handleChange(e, "counterClaimType")}
                  {...{ values, setFieldValue }}
                />
              </StyledFormElementsWrapper1>
              {/* Case description box */}
              {values?.counterClaimType && (
                <>
                  {inputs.map((input, key) => (
                    <StyledFormElementsWrapper
                      style={{ marginBottom: 15 }}
                      key={key}
                    >
                      <FormField
                        white
                        {...{
                          input,
                          key,
                          values,
                          handleChange,
                          errors,
                          handleSubmit,
                          touched,
                          handleBlur,
                          setFieldValue,
                        }}
                        disabled={!values.counterClaimType}
                      />
                    </StyledFormElementsWrapper>
                  ))}

                  <TotalClaimValue
                    ressolutionKind={values.resolutionKind}
                    values={values.counterClaim}
                    currencyType={values.currencyUnit}
                    disabled={!values.counterClaimType}
                    handleChange={handleChange}
                    label={"Counter"}
                    name={"counterClaim"}
                    {...{ errors }}
                    onBlur={(_) => {
                      setFieldTouched(`counterClaim`, true);
                    }}
                  />
                  {/* multiple upload */}
                  <MultipleUpload
                    values={values}
                    setFieldValue={setFieldValue}
                    getFileName={getFileName}
                    setFieldError={setFieldError}
                    handleDeleteAgreement={handleDeleteAgreement}
                    touched={touched}
                    errors={errors}
                    menuItems={caseDocumentType}
                    handleBlur={handleBlur}
                    disabled={!values.counterClaimType}
                    {...{ s3Upload }}
                  />
                </>
              )}
              {/* Additional Mediator for arbitration */}

              <StyledFormElementsWrapper1
                style={{ marginBottom: 15, marginTop: 15 }}
              >
                <StyledLabel>Select Arbitrator</StyledLabel>
                <CustomRadioGroup
                  radioInputs={[
                    { label: "Accept Claimant Arbitrator", value: "old" },
                    { label: "Suggest New Arbitrator", value: "new" },
                  ]}
                  name={"subscriptionType"}
                  handleChange={(e) => handleChange(e, "subscriptionType")}
                  {...{ values }}
                />
              </StyledFormElementsWrapper1>

              {values.subscriptionType === "old" ? (
                <AccordionContainer>
                  <ExpansionPanel
                    classes={{
                      root: "accordion-root",
                    }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src={require("../../../assets/images/dropdownIcon2.svg")}
                          alt="expand"
                        />
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                      classes={{
                        content: "accordion-content",
                        expandIcon: "accordion-icon",
                      }}
                    >
                      <AccordionHeading>Arbitrators</AccordionHeading>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <CustomTable
                        columns={[
                          {
                            sorting: false,
                            title: "Mediator Name",
                            headerStyle: {
                              fontSize: 12,
                            },
                            render: (rowData) => (
                              <span
                                style={{
                                  fontFamily: theme.fonts.primaryFontBold,
                                  fontSize: 12,
                                }}
                              >
                                {rowData?.name}
                              </span>
                            ),
                          },
                          {
                            field: "email",
                            sorting: false,
                            title: "Email",
                            headerStyle: {
                              fontSize: 12,
                            },
                            cellStyle: {
                              fontSize: 12,
                            },
                            render: (rowData) => (
                              <a href={`mailto:${rowData.email}`}>
                                {rowData.email}
                              </a>
                            ),
                          },
                          {
                            field: "mobile",
                            sorting: false,
                            title: "Phone",
                            headerStyle: {
                              fontSize: 12,
                            },
                            cellStyle: {
                              fontSize: 12,
                            },
                            render: (rowData) => (
                              <>{rowData?.mobile ? rowData?.mobile : "-"}</>
                            ),
                          },
                          {
                            field: "postalAddress",
                            sorting: false,
                            title: "postalAddress",
                            headerStyle: {
                              fontSize: 12,
                            },
                            cellStyle: {
                              fontSize: 12,
                            },
                            render: (rowData) => (
                              <div style={{ marginLeft: "25px" }}>
                                {rowData?.postalAddress
                                  ? rowData?.postalAddress
                                  : "-"}
                              </div>
                            ),
                          },
                          {
                            field: "documents",
                            sorting: false,
                            title: "Documents",
                            headerStyle: {
                              fontSize: 12,
                              // maxWidth: 85,
                            },
                            cellStyle: {
                              fontSize: 12,
                              // maxWidth: 85,
                            },
                            render: (rowData) => (
                              <HyperLink
                                onClick={() => window.open(rowData.documents)}
                              >
                                {getFileName(rowData.documents)}
                              </HyperLink>
                            ),
                          },
                        ]}
                        hidePagination
                        hideSearch
                        noToolbar
                        singularTitle=""
                        pluralTitle={"Arbitrator"}
                        state={{
                          data: location?.state?.requestedMediators,
                        }}
                        data={location?.state?.requestedMediators}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </AccordionContainer>
              ) : (
                <AdditionalMediator
                  {...{
                    values,
                    setFieldValue,
                    setFieldError,
                    touched,
                    errors,
                    handleBlur,
                  }}
                />
              )}

              {/* buttons */}
              <CaseActionCTA
                isBigScreen={isBigScreen}
                onClick={() => navigate(`/dashboard/requests`)}
                handleSubmit={handleSubmit}
                PrimaryOutlinedCTA={"Back"}
                PrimaryCTA={"Submit"}
              />
            </StyledForm>
          )}
        </Formik>
      </CreateCaseContainer>
    </Container>
  );
};

export default Index;

const TextAlign = styled.text`
  text-align: left;
`;

const UnorderList = styled.ul`
  padding: 0px 10px;
  & .data-row {
    display: flex;
    & .data-field {
      padding: 10px 0px;
      font-family: ${theme.fonts.primaryFontSemiBold};
      & .label {
        color: ${COLORS.INPUT_LABEL};
        font-size: 8px;
      }
      & .value {
        font-size: 10px;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    @media ${theme?.breakpoints?.sm_up} {
      & .data-field {
        & .label {
          color: ${COLORS.INPUT_LABEL};
          font-size: 10px;
        }
        & .value {
          font-size: 14px;
        }
        &:nth-child(2) {
          margin-left: 30px;
        }
      }
    }
  }
  & li {
    padding: 5px;
  }

  & .adhoc-pricing {
    text-align: center;
  }
`;
