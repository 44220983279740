import React, { useState, memo } from "react";
import { makeStyles } from "@material-ui/core";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import { polarToCartesian } from "./function";
import { StyledMenuItem } from "../../common/FormInputs";
import { Circle } from "../styles";
import theme from "../../../assets/theme";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  svg: {
    width: "200px",
    height: "200px",
  },
  legend: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  colorBox: {
    marginLeft: "12px",
    width: "20px",
    height: "20px",
    borderRadius: "8px",
    marginRight: theme.spacing(1),
  },
}));

const StagesPie = ({
  findSubStages,
  cumulativePercent,
  filterRef,
  filterData,
}) => {
  const classes = useStyles();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });

  const handleMouseOver = (content, event) => {
    // Get the mouse coordinates relative to the SVG
    const svg = event.currentTarget.ownerSVGElement;
    const { left, top } = svg.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;

    setTooltip({
      visible: true,
      content,
      x,
      y,
    });
  };

  const handleMouseOut = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  const handleClick = (label) => {
    filterRef.current = {
      ...filterRef.current,
      detailedStage: label,
    };
    filterData(label, filterRef.current, "detailedStage");
  };

  return (
    <React.Fragment>
      <Box className={classes.chartContainer}>
        {findSubStages?.length === 1 ? (
          findSubStages?.map((arr, index) => (
            <svg viewBox="0 0 200 200" className={classes.svg}>
              <Circle
                key={index}
                cx={90}
                cy={90}
                r={90}
                fill={arr.color}
                onMouseOver={(event) =>
                  handleMouseOver(
                    `${arr.label}: ${arr.percentage.toFixed(1)}%`,
                    event,
                  )
                }
                onMouseOut={handleMouseOut}
              />
            </svg>
          ))
        ) : (
          <svg width={200} height={200} viewBox="0 0 35 35">
            {findSubStages?.map((slice) => {
              const [startX, startY] = polarToCartesian(cumulativePercent);
              cumulativePercent += slice.percentage / 100;
              const [endX, endY] = polarToCartesian(cumulativePercent);

              const largeArcFlag = slice.percentage > 50 ? 1 : 0;

              return (
                <path
                  style={{ cursor: "pointer" }}
                  key={slice.label}
                  d={`M16,16 L${startX},${startY} A16,16 0 ${largeArcFlag} 1 ${endX},${endY} Z`}
                  fill={slice.color}
                  // stroke="white"
                  // strokeWidth="0.2"
                  onMouseOver={(event) =>
                    handleMouseOver(
                      `${slice.label}: ${slice.percentage.toFixed(1)}%`,
                      event,
                    )
                  }
                  onMouseOut={handleMouseOut}
                />
              );
            })}
          </svg>
        )}
        {tooltip.visible && (
          <div
            style={{
              position: "absolute",
              left: "65%",
              top: "64%",
              backgroundColor: "#000000",
              color: "#ffffff",
              padding: "5px",
              fontFamily: theme.fonts.primaryFontSemiBold,
              borderRadius: "8px",
              pointerEvents: "none",
            }}
          >
            {tooltip.content}
          </div>
        )}
      </Box>
      <Box className={isBigScreen ? `${classes.legend}` : ""}>
        {findSubStages?.map((slice, index) => (
          <Box
            key={index}
            className={classes.legendItem}
            style={{ marginTop: "10px" }}
          >
            <Box
              className={classes.colorBox}
              style={{ backgroundColor: slice.color }}
            />
            <StyledMenuItem onClick={() => handleClick(slice.detailedStage)}>
              {slice.label} ({slice.value})
            </StyledMenuItem>
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default memo(StagesPie);
