import React from "react";
import styled from "styled-components";
import COLORS from "../../assets/Colors";
import theme from "../../assets/theme";
import { PrimaryCTAButton } from "../common/Buttons";
import { Modal } from "@material-ui/core";
import "./component/Timeline.css";
import CaseStages from "./component/CaseStages";

const Timeline = ({ state, setState }) => {
  const totalDaysDifference = state?.timeLineData?.reduce(
    (accumulator, currentObject) => {
      // Add the daysDifference of the current object to the accumulator
      return accumulator + (currentObject.daysDifference || 0);
    },
    0,
  ); // Initialize the accumulator to 0

  return (
    <Modal
      open={state?.state}
      onClose={() => setState({ state: false })}
      disableRestoreFocus
    >
      <ModalContainer>
        <Container>
          <HeadingContainer>
            <Heading>{"Case Stages Timeline"}</Heading>
          </HeadingContainer>
          <Total>
            <Label>Total : </Label> <div> ({totalDaysDifference})</div>
          </Total>
          <TimeLineCard className="timelines-container">
            <TimeLineCardHeight>
              {state?.timeLineData?.length &&
                state?.timeLineData.map((data, idx) => (
                  <CaseStages data={data} key={idx} />
                ))}
            </TimeLineCardHeight>
          </TimeLineCard>

          <FormControls>
            <PrimaryCTAButton
              style={{ width: "42%", padding: "8px 8px" }}
              onClick={() => setState({ state: false })}
            >
              Close
            </PrimaryCTAButton>
          </FormControls>
        </Container>
      </ModalContainer>
    </Modal>
  );
};

export default Timeline;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 18px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 35px;
  padding-bottom: 30px;
`;

const TimeLineCard = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  @media${theme.breakpoints.sm_up} {
    width: 90%;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 1023px) {
    .timelines-item-content {
      max-width: 80%;
    }
  }
`;

const TimeLineCardHeight = styled.div`
  max-height: 280px;
  overflow: auto;
`;

const Total = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.BTN_GREEN};
  margin-right: 35px;
`;

const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;
