import React from "react";
import styled from "styled-components";
import "./Timeline.css";
import theme from "../../../assets/theme";
import { getCaseLabelByStatus } from "./function";
import { useMediaQuery, useTheme } from "@material-ui/core";

const CaseStages = React.memo(({ data }) => {
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  function formatDateTime(dateTimeStr) {
    const [date, time] = dateTimeStr.split(" ");

    // Parse the date part

    const [day, month, year] = date.split("/");

    // Parse the time part
    let [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, handling midnight (0 -> 12)

    // Format the time part

    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  }

  return (
    <TimelineContainer className="timelines-item">
      <div className="timelines-item-content">
        <H3>{data.name}</H3>
        <Paragraph>
          <P style={{ width: isBigScreen && "350px" }}>
            {getCaseLabelByStatus(data)}
          </P>{" "}
          <TimeLine>
            {data.daysDifference >= 0 && `(${data.daysDifference})`}
          </TimeLine>
        </Paragraph>
        <Time>{formatDateTime(data.time)}</Time>
        <Span className="circle" />
      </div>
    </TimelineContainer>
  );
});

export default CaseStages;

const TimelineContainer = styled.div``;

const H3 = styled.h3`
  color: #00838c;
`;

const P = styled.p`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #293461;
`;
const Time = styled.time`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #e1e3ee;
`;

const Span = styled.span``;

const Paragraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeLine = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #293461;
`;
