import { navigate } from "@reach/router";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { H2 } from "./Styles";

function index(props) {
  return (
    <>
      <H2 style={{ textAlign: "center", marginTop: "40px" }}>Blogs</H2>
      <CardContainer>
        <Card>
          <Icon
            src={require("../../../assets/images/JustActMailers_SME-01.png")}
          />
          <CardHeading>
            ROLE OF MEDIATION IN SME DISPUTES{" "}
            <NewIcon src={require("../../../assets/images/new-gif-icon.gif")} />
          </CardHeading>
          <CardBody>
            Small and Medium Sized Enterprises (SME) are categorized as
            businesses whose investment, revenue and employee base are
            restricted to predetermined limits...
          </CardBody>
          <BtnStyle onClick={() => navigate("/blog/role-of-mediation")}>
            Read More
          </BtnStyle>
        </Card>

        <Card>
          <Icon
            src={require("../../../assets/images/JustActMailers_1.1 Arbitrators-01.png")}
          />
          <CardHeading>
            What Kind of Training is Required to Build a Country of Arbitrators?{" "}
            <NewIcon src={require("../../../assets/images/new-gif-icon.gif")} />
          </CardHeading>
          <CardBody>
            Arbitration in India has evolved over the preceding centuries to
            become one of the most well- regarded modes of dispute resolution,
            especially in the commercial world...
          </CardBody>
          <BtnStyle onClick={() => navigate("/blog/country-of-arbitrators")}>
            Read More
          </BtnStyle>
        </Card>

        <Card>
          <Icon
            src={require("../../../assets/images/JustActMailers_country.png")}
          />
          <CardHeading>
            What Kind of Training is Required to Build a Country of Mediators?{" "}
          </CardHeading>
          <CardBody>
            Mediation is gaining ground as a mainstream dispute resolution
            process for settling commercial and financial disputes across the
            world...
          </CardBody>
          <BtnStyle onClick={() => navigate("/blog/country-of-mediators")}>
            Read More
          </BtnStyle>
        </Card>

        {/* <Card>
          <Icon
            style={{ height: 230 }}
            src={require("../../../assets/images/Mediation picture.jpg")}
          />
          <CardHeading>
            Culture of mediation: why has it not picked up in India?
          </CardHeading>
          <CardBody>
            Alternate Dispute Resolution (ADR) refers to modes by which disputes
            may be resolved without taking recourse to the court system.
            Mediation, among other ADR options, offers voluntary participation,
            flexibility...
          </CardBody>
          <BtnStyle
            onClick={() =>
              navigate(
                "/blog/culture-of-mediation-why-has-it-not-picked-up-in-india"
              )
            }
          >
            Read More
          </BtnStyle>
        </Card> */}
        {/* <Card>
          <Icon src={require("../../../assets/images/Thumbnail_Vismita.png")} />
          <CardHeading>
            Scope of Alternative Dispute Resolution for effectively resolving
            women- related disputes
          </CardHeading>
          <CardBody>
            Violence against women is a matter of grave concern. The immediate
            and long-term physical sexual, and mental consequences...
          </CardBody>
          <BtnStyle
            onClick={() => navigate("/blog/ADR-and-its-applications-to-women-related-disputes")}
          >
            Read More
          </BtnStyle>
        </Card> */}

        {/* <Card>
          <Icon src={require("../../../assets/images/Thumbnail4.png")} />
          <CardHeading>
            Resolving women related disputes effectively
          </CardHeading>
          <CardBody>
            Women are victims of different social evils. They are subjected to
            problems at their homes, at the workplace, in society, etc...
          </CardBody>
          <BtnStyle
            onClick={() => navigate("/blog/resolving-women-related-disputes")}
          >
            Read More
          </BtnStyle>
        </Card> */}
      </CardContainer>
    </>
  );
}

export default index;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px;
  @media (max-width: 960px) {
    margin: 40px 0px;
  }
`;
const CardBody = styled.div`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontRegular};
  margin: 0 20px 15px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const NewIcon = styled.img`
  width: 33px;
  height: 30px;
  vertical-align: middle;
`;

export const Icon = styled.img`
  width: 100%;
  height: 200;
  object-fit: contain;
  margin-right: 10px;
  color: ${COLORS.COLOR_DARK};
`;

const CardHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 18px;
  font-weight: 600;
  margin: 20px 20px 10px 20px;
  text-align: left;
`;
const Card = styled.div`
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 1%;
  @media (max-width: 960px) {
    margin: 20px 0px;
    width: 80%;
  }
`;

const BtnStyle = styled.section`
  color: #00838c;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  cursor: pointer;
  text-align: left;
  float: left;
  margin: 0 20px 20px 20px;
  font-family: ${theme.fonts.primaryFontBold};
`;
