import React, { Fragment } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { Formik } from "formik";
import FormField from "../../../common/FormField/FormField";
import { useState } from "react";
import { useEffect } from "react";
import PartyService from "../../../../services/PartyService";
import _ from "lodash";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { navigate } from "@reach/router";
import { CaseRequestSchema } from "../../../../helpers/validationSchema";
import labels from "../../../../helpers/labels.json";
import CaseService from "../../../../services/CaseService";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import useAlert from "../../../../hooks/useAlert";
import useCartCount from "../../../../hooks/useCartCount";
import { CustomCheckbox } from "../../../common/FormInputs";
import useSettings from "../../../../hooks/useSettings";
import { CircularProgress } from "@material-ui/core";

export default function AssignPartyModal({
  modal = { state: false },
  setModal,
  MTRef,
}) {
  const [state, setState] = useState({
    perPage: 50,
    data: [],
    page: 0,
    lastPage: 1,
    loading: true,
  });
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { setTriggerCount } = useCartCount();
  const { showAlert, hideAlert } = useAlert();
  const { setting } = useSettings();

  useEffect(() => {
    async function getParties() {
      try {
        const response = await PartyService.getParties(
          `?perPage=${state.perPage}&page=${state.page + 1}&status=active`,
        );
        setState((s) => ({
          ...response,
          data: _.uniqBy(
            [
              ...s.data,
              ...response.data.map(({ name, id }) => ({
                label: name,
                value: id,
              })),
            ],
            "value",
          ),
        }));
        return response;
      } catch (error) {
        throw error;
      }
    }
    if (state.page < state.lastPage) getParties();
  }, [state]);

  const acceptCase = async ({ partyId }, registrationFee) => {
    try {
      if (partyId && modal?.case?.id) {
        setLoader({ state: true, message: "Accepting Case..." });
        const response = await CaseService.acceptCase(modal.case.id, partyId);

        if (response) {
          enqueueSnackbar("Case accepted successfully", {
            variant: "success",
          });
          if (registrationFee && Number(registrationFee)) {
            setTriggerCount(true);
            navigate("/dashboard/cart");
          } else {
            navigate(
              "/dashboard/cases/" +
                modal.case.id +
                `?caseType=${modal?.case?.resolutionKind}`,
            );
          }
          MTRef.current.onSearchChange("");
          setModal(false);
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      hideAlert();
    }
  };

  const acceptPaymentInformationAlert = (registrationFee, res, values) => {
    showAlert({
      heading: "Accept Case Confirmation",
      desc: (
        <>
          <StyledText>Please note</StyledText>:{" "}
          {registrationFee?.total && Number(registrationFee?.total) ? (
            <StyledTextSemi>
              In order to proceed with the case registration, you will need to
              pay a{" "}
              {modal?.case?.resolutionKind === "arbitration"
                ? "arbitration"
                : "registration"}{" "}
              fee of{" "}
              <StyledText>
                {registrationFee?.unit}{" "}
                {parseFloat(registrationFee?.total).toFixed(2)} (inclusive of
                GST).{" "}
              </StyledText>
            </StyledTextSemi>
          ) : (
            <StyledTextSemi>
              In order to proceed with the case registration, you will need to
              pay a registration fee of{" "}
              <StyledText>
                {registrationFee?.unit}{" "}
                {parseFloat(registrationFee?.total).toFixed(2)} (inclusive of
                GST).{" "}
              </StyledText>
              <br />
              And your potential cost is{" "}
              <StyledText>
                {parseFloat(registrationFee?.potentialCost).toFixed(2)}
              </StyledText>
            </StyledTextSemi>
          )}{" "}
          {modal?.case?.resolutionKind !== "arbitration" && (
            <>
              If the case is successfully resolved{" "}
              {modal?.case?.resolutionKind === "negotiation"
                ? "negotiated"
                : modal?.case?.resolutionKind === "mediation"
                ? "mediated"
                : "arbitrated"}
              , you will need to pay a{" "}
              {modal?.case?.resolutionKind === "negotiation"
                ? "negotiation"
                : modal?.case?.resolutionKind === "mediation"
                ? "mediated"
                : "arbitrated"}{" "}
              success fee of{" "}
              <StyledText>
                {res?.fee?.unit} {parseFloat(res?.fee?.total).toFixed(2)}{" "}
                (inclusive of GST)
              </StyledText>{" "}
              to collect your Settlement Agreement.
            </>
          )}
        </>
      ),
      primaryBtnText: "Accept",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => acceptCase(values, registrationFee?.total),
      isOpen: true,
      onDialogClose: () => hideAlert(),
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  const adhocPaymentInformationAlert = (registrationFee, values) => {
    showAlert({
      heading: "Accept Case Confirmation",
      desc: (
        <div className="adhoc-pricing">
          <li>
            <b>
              You will be charged a non-refundable license fee of{" "}
              {registrationFee.unit}{" "}
              {parseFloat(registrationFee?.total).toFixed(2) || 0.0} for using
              JustAct. The license validity is {registrationFee?.roomValidity}{" "}
              months.
            </b>
          </li>
          <br />
          <li>
            <b>
              You will also need to pay the{" "}
              {modal?.case?.resolutionKind === "mediation"
                ? "mediator"
                : "arbitrator"}{" "}
              as per the terms agreed between the parties and the{" "}
              {modal?.case?.resolutionKind === "mediation"
                ? "mediator"
                : "arbitrator"}
              . JustAct will not be responsible for any non-payment in this
              regard.
            </b>
          </li>
        </div>
      ),
      primaryBtnText: "Accept",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => acceptCase(values, registrationFee?.total),
      isOpen: true,
      onDialogClose: () => hideAlert(),
      descriptionTextStyle: {
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  async function onFormSubmit(values) {
    if (
      modal?.case?.respondentStatus === "accepted" ||
      modal?.case?.paymentCode
    ) {
      // already accepted by another respondent or case contains payment code
      acceptCase(values); // Accept directly when 2 respondent accept the case
    } else {
      try {
        setLoader({ state: true, message: "Fetching details..." });
        const registrationFee =
          (
            await CaseService.getCase(
              modal.case.id,
              `?estimateFeeKind=registrationFee&partyId=${values?.partyId}`,
            )
          )?.fee || {};

        if (modal?.case?.subscriptionKind === "adhoc") {
          adhocPaymentInformationAlert(registrationFee, values);
        } else {
          const res = await CaseService.getCase(
            modal?.case?.id,
            `?estimateFeeKind=successFee&estimateRespondentPartyId=${values?.partyId}`,
          );
          if (res?.fee?.total) {
            acceptPaymentInformationAlert(registrationFee, res, values);
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Drawer anchor={"right"} open={modal.state}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{labels.select_party}</Heading>
          <CloseModal
            onClick={() => setModal({ state: false })}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        {state.loading ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          <AgentFormContainer>
            <Formik
              initialValues={{
                partyId: "",
                agreement: false,
              }}
              enableReinitialize
              validateOnBlur
              validateOnChange
              validationSchema={CaseRequestSchema}
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
              }) => (
                <Fragment>
                  {state?.data?.length === 0 && (
                    <HelperText
                      style={{ position: "relative", bottom: 0, marginTop: 15 }}
                    >
                      {labels.no_party_found}
                    </HelperText>
                  )}
                  <Relative>
                    <FormField
                      input={{
                        name: "partyId",
                        label: labels.select_existing,
                        type: "select",
                      }}
                      showId
                      menuItems={state.data}
                      {...{
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldError,
                      }}
                    />
                  </Relative>
                  {modal?.case?.subscriptionKind !== "adhoc" ? (
                    <CheckboxFormControl>
                      <CheckboxContainer>
                        <CustomCheckbox
                          name="agreement"
                          checked={values.agreement}
                          onChange={handleChange}
                        />
                        <CheckBoxLabel>
                          I Agree to JustAct being my ADR provider and agree to{" "}
                          <HyperLink
                            onClick={() => {
                              window.open(
                                setting?.rulesDocumentUrl ||
                                  "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/13/1620883614227-f4dbb5a2-4d9c-4a80-8645-b3500816dead/JustAct_Rules.pdf",
                              );
                            }}
                          >
                            JustAct Rules
                          </HyperLink>{" "}
                          of Negotiation, Mediation, Arbitration, as applicable.
                        </CheckBoxLabel>
                      </CheckboxContainer>
                    </CheckboxFormControl>
                  ) : null}
                  <Flex>
                    <PrimaryOutlinedCTAButton
                      type="button"
                      onClick={() => navigate("/dashboard/parties/create")}
                      style={{
                        width: "47%",
                      }}
                    >
                      {labels.create_new_party}
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      type="submit"
                      onClick={handleSubmit}
                      disabled={
                        !values.partyId ||
                        (!values.agreement &&
                          modal?.case?.subscriptionKind !== "adhoc")
                      }
                      style={{
                        width: "47%",
                      }}
                    >
                      {labels.select_party_btn}
                    </PrimaryCTAButton>
                  </Flex>
                </Fragment>
              )}
            </Formik>
          </AgentFormContainer>
        )}
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 100vw;
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 555px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 26px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  height: 100%;
  & form {
    padding-top: 10px;
  }
`;

const Relative = styled.div`
  position: relative;
`;
const HelperText = styled.div`
  position: absolute;
  left: 3px;
  bottom: -15px;
  position: absolute;
  font-size: 10px;
  font-family: openSans-SemiBold, sans-serif;
  color: #f44336;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;

const CheckboxFormControl = styled.div`
  margin-top: 15px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: inherit;
`;

// export const CheckBoxLabel = styled.span`
//   font-family: ${theme.fonts.primaryFontRegular};
//   font-size: 14px;
//   color: ${COLORS.COLOR_DARK};
//   cursor: inherit;
// `;

// export const CheckboxContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;

// export const CheckboxFormControl = styled.div`
//   margin-top: 15px;
//   width: 436px;
