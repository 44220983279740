import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT
} from "../../../../helpers/constants";
import ClaimBox from "../common/ClaimBox";
import CaseAgreement from "../common/CaseAgreement";
import AgreementDocCard from "../common/AgreementDocCard";
import _ from "lodash";
import { Spacing } from "../styles";

function NegotiationState({
  currentState,
  negotiationRounds,
  isRespondent,
  ownerId,
  respondentId,
  formikRef,
  caseDetails,
  showCta
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */
  const checkShowRespondentView = rounds => {
    return rounds?.respondentClaims?.some(
      el =>
        (el?.offer?.status === "offered" ||
          el?.offer?.statusAlais === "makeOffer") &&
        el?.offer?.offerorId === respondentId
    );
  };

  const RenderClaimBox = ({
    round,
    status,
    expand,
    errorText,
    showRespondantView,
    index,
    caseId
  }) => {
    return (
      <ClaimBox
        title={`Round ${negotiationRounds[round].negotiationRound} of ${caseDetails?.negotiationRoundLimit}`}
        key={round}
        expand={expand}
        isRespondent={isRespondent}
        claimItems={negotiationRounds[round]}
        enableCheckBox={
          negotiationRounds?.length - 1 === index
            ? currentState?.enableSelection
            : false
        }
        showRespondantView={
          showRespondantView ||
          checkShowRespondentView(negotiationRounds[round])
        }
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        negotiationRound={negotiationRounds[round].negotiationRound}
        formikRef={el => (formikRef.current[index] = el)}
        respondentId={respondentId}
        caseId={caseId}
        roundNumber={negotiationRounds[round].negotiationRound}
      />
    );
  };

  const AgreementSection = () => {
    return (
      <>
        <Spacing>
          <CaseAgreement
            title={`Final Award Summary for Case ID: ${caseDetails?.id}`}
            message={caseDetails?.negotiationSummary}
            summaryStatus={caseDetails?.summaryStatus === "completed"}
            isPaid={currentState?.cta?.length === 0}
          />
        </Spacing>
        <Spacing>
          <AgreementDocCard
            id={caseDetails?.id}
            status={caseDetails.status}
            isRespondent={isRespondent}
            hasRating={caseDetails?.rating}
            hasAgreement={caseDetails?.hasAgreementDocument}
            type={`Download`}
          />
        </Spacing>
      </>
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <Spacing>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={true}
                round={round}
                index={idx}
                caseId={caseDetails?.id}
                errorText={"Respondent Declined the Case"}
                status={caseDetails.respondentStatus === "declined" && "error"}
              />
            ))}
        </Spacing>
      );
    case AWAITING_RESPONDANT_QUIT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                caseId={caseDetails?.id}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                index={idx}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Case Closed by Case Manager"
                    : "Negotiation In Progress"
                }
              />
            ))}
        </div>
      );
    case RESPONDANT_ONBOARDED:
      return (
        <Spacing>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                expand={true}
                round={round}
                index={idx}
                caseId={caseDetails?.id}
                errorText={"Respondent Declined the Case"}
                status={caseDetails.respondentStatus === "declined" && "error"}
              />
            ))}
        </Spacing>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <Spacing>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                caseId={caseDetails?.id}
                index={idx}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={
                  _.keys(negotiationRounds).length - 1 !== idx
                    ? "error"
                    : negotiationRounds?.length - 1 === idx &&
                      !showCta &&
                      "warning"
                }
                errorText={
                  negotiationRounds?.length - 1 === idx && !showCta
                    ? "Offer sent, waiting for response"
                    : ""
                }
              />
            ))}
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <Spacing>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                caseId={caseDetails?.id}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                index={idx}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Case Quit by Claimant"
                    : "Negotiation In Progress"
                }
              />
            ))}
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <Spacing>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                caseId={caseDetails?.id}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                index={idx}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Case Quit by Respondent"
                    : "Negotiation In Progress"
                }
              />
            ))}
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                caseId={caseDetails?.id}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                status={"error"}
                index={idx}
                errorText={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "Case Closed by Case Manager"
                    : "Negotiation In Progress"
                }
              />
            ))}
        </div>
      );
    case NEGOTIATION_REACHED:
      return (
        <Spacing>
          {_.keys(negotiationRounds)
            ?.sort()
            ?.map((round, idx) => (
              <RenderClaimBox
                key={round}
                caseId={caseDetails?.id}
                expand={_.keys(negotiationRounds).length - 1 === idx}
                round={round}
                index={idx}
                status={
                  _.keys(negotiationRounds).length - 1 === idx
                    ? "success"
                    : "error"
                }
                showRespondantView={true}
              />
            ))}
        </Spacing>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  caseId={caseDetails?.id}
                  expand={false}
                  round={round}
                  index={idx}
                  status={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "success"
                      : "error"
                  }
                  showRespondantView={true}
                />
              ))}
          </Spacing>
          <AgreementSection />
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  caseId={caseDetails?.id}
                  expand={false}
                  round={round}
                  index={idx}
                  status={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "success"
                      : "error"
                  }
                  showRespondantView={true}
                />
              ))}
          </Spacing>
          <AgreementSection />
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            {_.keys(negotiationRounds)
              ?.sort()
              ?.map((round, idx) => (
                <RenderClaimBox
                  key={round}
                  expand={false}
                  round={round}
                  caseId={caseDetails?.id}
                  index={idx}
                  status={
                    _.keys(negotiationRounds).length - 1 === idx
                      ? "success"
                      : "error"
                  }
                  showRespondantView={true}
                />
              ))}
          </Spacing>
          <AgreementSection />
        </>
      );
    default:
      return null;
  }
}

export default React.memo(NegotiationState);
